<template>
  <div class="settled" :class="{ app: isApp }">
    <!-- 手机端 -->
    <div v-if="isApp" class="regesit-app">
      <div class="titles">
        <div @click="goto(1)" class="stepColor">管理员注册</div>
        <div :class="status > 1 ? 'stepColor' : ''" class="line">—</div>
        <div @click="goto(2)" :class="status > 1 ? 'stepColor' : ''">
          创建企业
        </div>
        <div :class="status > 2 ? 'stepColor' : ''" class="line">—</div>
        <div
          @click="goto(4)"
          :class="[status > 3 ? 'stepColor' : '', error ? 'redColor' : '']"
        >
          {{ error ? "入驻失败" : "入驻成功" }}
        </div>
      </div>
      <div class="fromDate">
        <!-- 管理员的 -->
        <administrators
          @postKey="postKey"
          :params="invit_info"
          v-if="status == 1"
          @confirm="adminInfo"
        />
        <!-- 创建企业 -->
        <createEnterprise
          @isok="isok"
          :params="invit_info"
          :keys="keys"
          v-if="status == 2"
        />
        <!-- 审核状态 -->
        <examineStatus
          @registerAgain="registerAgain"
          @loginClick="loginClick"
          :message="message"
          v-if="status == 3"
        />
      </div>
    </div>
    <!-- PC端 -->
    <div class="regesit" v-else>
      <div class="titles">
        <div @click="goto(1)" class="stepColor">①管理员注册</div>
        <div :class="status > 1 ? 'stepColor' : ''">—</div>
        <div @click="goto(2)" :class="status > 1 ? 'stepColor' : ''">
          ②创建企业
        </div>
        <div :class="status > 2 ? 'stepColor' : ''">—</div>
        <div
          @click="goto(4)"
          :class="[status > 3 ? 'stepColor' : '', error ? 'redColor' : '']"
        >
          {{ error ? "③入驻失败" : "③入驻成功" }}
        </div>
      </div>
      <div class="fromDate">
        <!-- 管理员的 -->
        <administrators
          @postKey="postKey"
          :params="invit_info"
          v-if="status == 1"
          @confirm="adminInfo"
        />
        <!-- 创建企业 -->
        <createEnterprise
          @isok="isok"
          :params="invit_info"
          :keys="keys"
          v-if="status == 2"
        />
        <!-- 审核状态 -->
        <examineStatus
          @registerAgain="registerAgain"
          :message="message"
          v-if="status == 3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, postJSON } from "@/server/ajax.js";
import { wxShareConfige, shareWXQQ, isMobile } from "@/utils/wxConfig.js";
const URLS = {
  STATUS: "/api/users/company_station/status",
  REGISTER: "/base/company/station",
};
import administrators from "./components/administrators.vue";
import createEnterprise from "./components/createEnterprise.vue";
import examineStatus from "./components/examineStatus.vue";
export default {
  name: "settled",
  components: {
    administrators,
    createEnterprise,
    examineStatus,
  },
  data() {
    return {
      error: false,
      status: 1,
      code: "", //邀请码
      keys: {},
      examineMsg: {}, // 平台审核的状态
      admin_info: {},
      company_info: {},
      loginInfo: {
        user: "",
        password: "",
      },
      message: {
        status: 1,
        name: "",
      }, // 审核状态的状态值
      invit_info: {
        id: 0,
      },
      isApp: false,
    };
  },
  created() {
    let code = this.$route.query.code;
    this.isApp = isMobile();
    if (code) {
      get(URLS.STATUS, { code }).then((res) => {
        if (res.code == 200) {
          if (res.data.status == 0) {
            // 0代表带审核
            this.status = 3;
            this.examineMsg = {
              apply_date: res.data.created_at,
              serial_number: res.data.serial_number,
              encode: code,
            };
          } else if (res.data.status == 2) {
            this.error = false;
            this.status = 4;
            this.message = res.data;
          } else if (res.data.status == 3) {
            this.error = true;
            this.status = 4;
            this.message = res.data;
          }
        }
      });
    }
    let query = this.$route.query;
    // console.log('平台入驻');
    if (query.k) {
      this.code = query.k;
      this.getInvitInfo();
      this.activeLink(query.k);
    }
  },
  async mounted() {
    this.isApp = isMobile();
    let wxConfig = await wxShareConfige();
    if (this.$route.query.k) {
      this.$axios
        .postJSON("/base/invite/detail", { serial_number: this.$route.query.k })
        .then((res) => {
          if (res.code == 200) {
            if (wxConfig === "1") {
              shareWXQQ(res.data.invite_set);
            }
          }
        });
    }
  },
  methods: {
    postKey(val) {
      if (val.mobile) {
        val.mobile = Number(val.mobile);
      }
      this.keys = val;
      this.status = 2;
    },
    activeLink(link) {
      this.$axios
        .postJSON("/base/invite/active", { serial_number: link })
        .then((res) => {
          if (res.code == 200) {
            console.log("激活成功");
          }
        })
        .catch((err) => {
          err.msg && this.$message.error(err.msg);
        });
    },
    adminInfo(data) {
      this.admin_info = data;
      this.status = 2;
    },
    goto(val) {
      if (val <= this.status) {
        this.status = val;
      }
    },
    isok(val) {
      this.company_info = val;
      if (this.admin_info.mobile) {
        this.admin_info.mobile = Number(this.admin_info.mobile);
      }
      let params = {
        is_read_agree: true,
        admin_info: this.admin_info,
        company_info: this.company_info,
        mobile_verity_code: this.admin_info.valid_code,
      };
      let query = this.$route.query;
      if (query.k) {
        params.invite_serial_number = query.k;
      }
      this.loginInfo.user = this.admin_info.mobile;
      this.loginInfo.password = this.admin_info.password;
      postJSON("/base/company/station", params).then((res) => {
        if (res.code == 200) {
          this.status = 3;
          let company = res.data.company;
          company.status = 2;
          company.admin_name = this.admin_info.true_name;
          company.admin_mobile = this.admin_info.mobile;
          this.message = company;
        }
      });
    },
    loginClick() {
      //登录进入用户中心
      let data = {
        u: this.admin_info.mobile,
        p: this.admin_info.password,
      };
      this.$axios.postJSON("/base/user/login", data).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("AccessToken", res.data.AccessToken);
          // this.$router.push('/mySelf/index');
          // window.open(location.host+"/platform")
          window.open("/main/index");
        }
      });
    },
    // 进入重新注册
    registerAgain() {
      this.status = 1;
      this.error = false;
    },
    //设置邀请信息
    getInvitInfo() {
      let params = {
        serial_number: this.code,
      };
      postJSON("/base/invite/detail", params).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          // if(data.invite_method == 1){
          this.invit_info = data;
          // }
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
.settled {
  width: 1200px;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  & > div {
    width: 50%;
  }
  .regesit {
    // height: 550px;
    background-color: #fff;
    padding: 10px 30px 30px;
    box-sizing: border-box;
    .titles {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin: 22px auto 40px;
      width: 380px;
      color: #999999;
    }
    .stepColor {
      color: #4387f8;
      user-select: none;
      &:nth-of-type(odd) {
        cursor: pointer;
      }
    }
    .fromDate {
      padding: 0px 60px;
    }
    .redColor {
      color: red;
    }
  }
}
// 手机端样式
.settled.app {
  font-size: calc(100vw / 750 * 20);
  width: 100vw;
  margin: 0 1.5em;
  display: block;
  & > div {
    width: 100%;
  }
  .regesit-app {
    background-color: #ffffff;
    position: relative;
    top: -2em;
    border-radius: 1em 1em 0 0;
    .titles {
      display: flex;
      font-size: 1.6em;
      padding: 0.2em 0.3em;
      padding-top: 1em;
      justify-content: center;
      color: #999999;
      .stepColor {
        color: #4387f8;
      }
      .line {
        margin: 0 0.5em;
      }
    }
  }
}
</style>
<style lang="scss">
.app-el-form {
  font-size: calc(100vw / 750 * 20);
  // el-form组件代码
  .el-form-item {
    margin-bottom: 3rem;
  }
  .el-form-item__content {
    line-height: 1em;
    font-size: 1.4rem;
  }
  .el-input {
    font-size: 1.4rem;
  }
  .el-input__inner {
    line-height: 1;
    font-size: 1.4rem;
    height: 2.5em;
    padding-left: 1rem;
  }
  .el-form-item--small .el-form-item__error {
    padding-top: 0.5rem;
  }
  .el-form-item__error {
    font-size: 1.3rem;
  }
  .el-button {
    border-radius: 0.2em;
  }
  .el-button--danger {
    line-height: 1.75;
    font-size: 1.4rem;
  }
  .el-checkbox {
    font-size: 1.2rem;
    margin-right: 0.4em;
  }
  .el-checkbox__inner {
    width: 1.4rem;
    height: 1.4rem;
    &::after {
      width: 0.5rem;
      height: 0.7rem;
      border: 0.2rem solid #ffffff;
      border-left: 0;
      border-top: 0;
      left: 0.3rem;
    }
  }
}
</style>
