<template>
  <el-row :gutter="20">
		<!-- 手机版 -->
		<div v-if="isApp" class="app-form-box">
			<el-form class="app-el-form"
				:rules="rules"
				ref="ruleForm"
				label-position="right"
				size="small"
				label-width="0px"
				:model="fromData"
			>
				<el-form-item prop="mobile">
				  <el-input
				    placeholder="请输入管理员手机号码"
				    v-model="fromData.mobile"
				    class="input-with-select"
				    type="text"
				  >
				    <template slot="prepend">+86</template>
				  </el-input>
				</el-form-item>
				<el-form-item prop="is_valid">
				  <huakuaiApp ref="huakuai" :mobile="fromData.mobile" @isOk="validBtn"></huakuaiApp>
				</el-form-item>
				<el-form-item prop="valid_code">
					<div class="code-box">
						<el-input
						  placeholder="请输入短信验证码"
						  v-model="fromData.valid_code"
						  type="text"
						/>
						<el-button @click="getGenerate" :disabled="disabled" class="code-msg-btn" size="small"
						  type="danger" plain  >{{ showWorld }}</el-button >
					</div>
				</el-form-item>
				<el-form-item prop="password" class="pass-info">
				  <el-input
				    placeholder="请输入登录密码"
				    v-model="fromData.password"
				    show-password
				  />
				  <!-- <div class="info">密码为8-16位字母/数字/符号，不能是纯数字</div> -->
				</el-form-item>
				<el-form-item prop="true_name">
				  <el-input
				    placeholder="请输入管理员姓名"
				    v-model="fromData.true_name"
				    type="text"/>
				</el-form-item>
				<el-form-item prop="email">
				  <el-input
				    placeholder="请输入可以联系管理员电子邮箱(非必填)"
				    v-model="fromData.email"
				    type="text"
				  />
				</el-form-item>
				<div class="btns" :span="24">
				    <el-button
				      :disabled="!fromData.is_read_agree"
				      @click="submits"
				      style="width: 100%"
				      type="danger"
				      >下一步</el-button
				    >
				</div>
				<div :span="24" v-if="agreeList&&agreeList.length>0"  class="agreement-box">
				    <el-checkbox v-model="fromData.is_read_agree" class="radio-box"></el-checkbox>
				    <p class="agree-info">
				      我已阅读并同意<span v-for="(its,index) in agreeList" :key="index">
				        <span class="agree" @click="agreeBtn(its)">《{{its.title}}》</span><template v-if="index<agreeList.length-1">和</template>
				      </span>
				    </p>
				</div>
			</el-form>
		</div>
		<!-- PC端 -->
    <el-form v-else
      :rules="rules"
      ref="ruleForm"
      label-position="right"
      size="small"
      label-width="0px"
      :model="fromData"
    >
      <el-col :span="24">
        <el-form-item prop="mobile">
          <el-input
            placeholder="请输入管理员手机号码"
            v-model="fromData.mobile"
            class="input-with-select"
            type="text"
          >
            <template slot="prepend">+86</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="is_valid">
          <huakuai ref="huakuai" width="420px" :mobile="fromData.mobile" @isOk="validBtn"></huakuai>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item prop="valid_code">
          <el-input
            placeholder="请输入短信验证码"
            v-model="fromData.valid_code"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-button
          @click="getGenerate"
          :disabled="disabled"
          style="width: 100%;"
          size="small"
          type="danger"
          plain
          >{{ showWorld }}</el-button
        >
      </el-col>
      <el-col :span="24">
        <el-form-item prop="password">
          <el-input
            placeholder="请输入登录密码"
            v-model="fromData.password"
            show-password
          />
          <!-- <div class="info">密码为8-16位字母/数字/符号，不能是纯数字</div> -->
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="true_name">
          <el-input
            placeholder="请输入管理员姓名"
            v-model="fromData.true_name"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="email">
          <el-input
            placeholder="请输入可以联系管理员电子邮箱(非必填)"
            v-model="fromData.email"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col class="btns" :span="24">
          <el-button
            :disabled="!fromData.is_read_agree"
            @click="submits"
            style="width: 100%"
            type="danger"
            >下一步</el-button
          >
      </el-col>
      <el-col :span="24" v-if="agreeList&&agreeList.length>0"  class="agreement-box">
          <el-checkbox v-model="fromData.is_read_agree" class="radio-box"></el-checkbox>
          <p class="agree-info">
            我已阅读并同意<span v-for="(its,index) in agreeList" :key="index">
              <span class="agree" @click="agreeBtn(its)">《{{its.title}}》</span><template v-if="index<agreeList.length-1">和</template>
            </span>
          </p>
      </el-col>
    </el-form>
  </el-row>
</template>

<script>
import { validatePhoneTwo, validatePassword,validateEMail } from "@/utils/validate.js";
import { post } from "@/server/ajax.js";
import huakuai from "@/views/login/loginHome/huakuai.vue"
import huakuaiApp from "@/views/login/loginHome/huakuaiApp.vue"
import { isMobile } from "@/utils/wxConfig.js";
const URL = {
  GENERATE: "/api/captcha/generate", // 获取验证码接口
  SEND: "/api/captcha/send", //验证验证码
  ADMINREG: "/api/users/company_station/adminreg", // 管理员注册接口
};
export default {
  props:['model','params'],
  components:{
    huakuai,
		huakuaiApp,
  },
  data() {
    return {
      dialogVisible: false,
      n: 60,
      showWorld: "点击获取",
      disabled: true, //获取验证码是否禁用
      img: {
        b64s: "",
        captcha_id: "",
      },
      regestCode: "",
      fromData: {
        mobile: "", //电话号码
        is_valid:false,
        valid_code: "", // 验证号码
        password: "", //密码
        true_name: "", // 姓名
        email: "", // 电子邮箱
        set_type:1,
        is_read_agree: false, // 勾选
      },
      saveData:{},
      times: null, // 定时器
      agreeList:[],
			isApp:false,
      rules: {
        mobile: [{ validator: validatePhoneTwo, trigger: "blur" }],
        valid_code: [
          { required: true, message: "请输入验证号码", trigger: "blur" },
        ],
        is_valid:[{validator:(rule,val,call)=>{
            if(val){
              call();
            }else{
              call(new Error("请拖动滑块验证"))
            }
          },
        }],
        // password: [{ validator: validatePassword, trigger: "blur" }],
        password: [{  required: true, message: "请输入登录密码", trigger: "blur" }],
        true_name: [
          { required: true, message: "请输入你的姓名", trigger: "blur" },
        ],
        email: [{validator:validateEMail, trigger: "blur" }],
      },
    };
  },
  created(){
    this.getAgreeList();
		this.isApp = isMobile();
    if(this.params && this.params.id){
      this._initParams();
    }
  },
  watch:{
    'params.id'(val){
      this._initParams();
    },
  },
  methods: {
    _initParams(){
      let data = this.params;
      this.fromData.mobile = data.invite_contact_mobile;
      this.fromData.true_name = data.invite_contact_name;
      this.fromData.email = data.invite_contact_email;
    },
    validBtn(data){
      this.saveData = data;
			if(data.isOk){
				this.disabled = false;
				this.fromData.is_valid = true;
			}
    },
    agreeBtn(row){
      let routeUrl = this.$router.resolve({
        path:'/register/agreement',
        query:{
          id:row.id,
        }
      })
      window.open(routeUrl.href, '_blank');
    },
    getAgreeList(){ //入驻协议
      this.$axios.get('/setting/agreement/station_list').then(res=>{
        if(res.code==200){
          let data = res.data;
          if(data && data.length>0){
            this.agreeList = res.data;
          }else{
            this.fromData.is_read_agree = true;
          }
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 获取验证码
    getGenerate() {
      this.$refs.ruleForm.validateField("mobile", (val) => {
        if (val) {
          this.$message({
            message: val,
            type: "warning",
          });
        }else if(!this.fromData.is_valid){
					this.$refs.huakuai.resets();
          this.$message.error("请向右滑动滑块验证")
        } else {
          this.regestCode = "";
          let val = this.$utils.cloneDeep(this.saveData);
          let params = {
            mobile: Number(this.fromData.mobile),
            first: {
              x: val.data1.x,
              y: val.data1.y,
              time: val.data1.time,
            },
            second: {
              x: val.data2.x,
              y: val.data2.y,
              time: val.data2.time,
            },
          };
					let that = this;
          this.$axios.postJSON('/base/company/station/send/mobilecode',params).then(res=>{
            if(res.code == 200){
              this.$message.success("发送成功");
              const that = this;
              this.timers = setInterval(() => {
                that.n--;
                if (that.n < 1) {
                  clearInterval(that.timers);
                  that.showWorld = "点击获取验证码";
                  that.n = 60;
									that.fromData.is_valid = false;
									that.disabled = false;
                } else {
                  that.showWorld = that.n + "秒后再次获取";
                  that.disabled = true;

                }
              }, 1000);
            }
          }).catch(err=>{
            this.$refs.huakuai.resets();
            this.disabled = false;
          })
        }
      });
    },
    // 提交下一步
    submits() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.fromData));
          params.is_read_agree = "true";
          this.$emit("confirm",this.fromData)
        } else {
          this.$message({
            message: "请填写完整的信息",
            type: "warning",
          });
        }
      });
    },
    // 验证验证码
    getValidCode() {
      if (!this.regestCode) {
        this.$message({
          message: "请填写验证码",
          type: "warning",
        });
        return;
      }
      let params = {
        receiver: this.fromData.mobile,
        verify_value: this.regestCode,
        captcha_id: this.img.captcha_id,
        method: "mobile",
      };
      post(URL.SEND, params).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // 验证成功逻辑
          this.dialogVisible = false;
          this.disabled = true;
          this.showMessage = this.n + "秒后再次获取";
          this.times = setInterval(() => {
            this.n--;
            this.showMessage = this.n + "秒后再次获取";
            if (this.n <= 0) {
              clearInterval(this.times);
              this.disabled = false;
              this.showMessage = "点击获取";
              this.n = 60;
            }
          }, 1000);
          return;
        }
      },(err) => {
        this.dialogVisible = false;
      });
    },
  },
  destroyed() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner:focus {
  border-color: red !important;
}
.agreement-box{
  display: flex;
  .radio-box{
    margin-right:8px;
  }
}
.imgsB {
  margin-bottom: 30px;
  text-align: center;
}
.imgsA {
  text-align: center;
}
.btns{
    margin-top: 10px;
    margin-bottom: 10px;
}
.info{
  font-size: 12px;
  color:#999999;
}
.agree-info{
  color:#666666;
}
.agree{
  color:#F26161;
  cursor: pointer;
}
</style>
<style lang="scss">
.app-form-box{
	font-size: calc(100vw/750 * 20);
	padding:0 1.5rem;
	padding-top:2rem;
	padding-bottom:1.5rem;
}
.app-el-form{
	font-size: calc(100vw/750 * 20);
	// el-form组件代码
	.el-form-item{
		margin-bottom: 3em;
	}
	.el-form-item__content{
		line-height: 1.5;
		font-size: 1em;
	}
	.el-input{
		font-size: 1.1em;
	}
	.el-input__inner{
		line-height:1.5;
		font-size: 1.1em;
		height: 3em;
		padding-left:1em;
	}
	.el-form-item--small .el-form-item__error{
		padding-top:.4em;
	}
	.el-form-item__error{
		font-size: 1.4em;
	}
	.el-button--danger{
		line-height: 1.5;
		font-size: 1.4em;
	}
	.el-checkbox{
		font-size: 1.2em;
		margin-right: .4em;
	}
	.el-checkbox__inner{
		width: 1.4em;
		height: 1.4em;
		&::after{
			width:.5em;
			height:.7em;
			border:.2em solid #FFFFFF;
			border-left:0;
			border-top:0;
			left:.3em;
		}
	}
	
	//
	.code-box{
		display: flex;
		.code-msg-btn{
			width: 8em;
			margin-left:.5em;
		}
	}
	.pass-info .info{
		font-size: 1.3rem;
		margin-top:.5rem;
		color:#999999;
	}
	.agreement-box{
		margin-top: 1em;
		font-size: 1.4rem;
	}
}
</style>
