<template>
  <el-row v-if="status">
    <!-- app端 -->
    <div v-if="isApp" class="app-status">
      <div class="examin">
        <p><i class="el-icon-success"></i>恭喜您平台审核通过，入驻成功</p>
      </div>
      <div class="message">
        <p>
          组织名称：<span class="company-name">{{ message.name }}</span>
        </p>
        <p>统一社会信用代码：{{ message.company_code }}</p>
        <p>创建人：{{ message.admin_name }}</p>
        <p>手机：{{ message.admin_mobile }}</p>
        <p>
          <span> 入驻日期：{{ message.station_date }} </span>
        </p>
      </div>
      <div class="btns">
        <el-button
          style="
            width: 100%;
            font-size: 1.4em;
            line-height: 1.5;
            border-radius: 0.2em;
          "
          type="primary"
          @click="loginBtn"
          >打开电脑端，进入租户工作台</el-button
        >
      </div>
    </div>
    <!-- PC端 -->
    <div v-else>
      <el-col class="examin" :span="24">
        <p><i class="el-icon-success"></i>恭喜您平台审核通过，入驻成功</p>
      </el-col>
      <el-col class="message" :span="24">
        <p>
          组织名称：<span class="company-name">{{ message.name }}</span>
        </p>
        <p>统一社会信用代码：{{ message.company_code }}</p>
        <p>创建人：{{ message.admin_name }}</p>
        <p>手机：{{ message.admin_mobile }}</p>
        <p>
          <span> 入驻日期：{{ message.station_date }} </span>
        </p>
      </el-col>
      <el-col class="btnsError" :span="24">
        <el-button
          style="width: 100%; font-size: 18px"
          type="danger"
          @click="loginBtn"
          >进入租户工作台</el-button
        >
      </el-col>
    </div>
  </el-row>
  <!-- 入驻失败 -->
  <el-row v-else>
    <!-- 手机端 -->
    <div v-if="isApp" class="app-status">
      <div class="examinError">
        <p><i class="el-icon-error"></i>审核完成，入驻失败</p>
      </div>
      <div class="messages">
        <p>提交时间：{{ message.created_at }}</p>
        <p>
          {{ message.audit_opinion }}
        </p>
        <p>申请编号：{{ message.serial_number }}</p>
      </div>
      <div class="message">
        <p>组织名称：{{ message.name }}</p>
        <p>统一社会信用代码：{{ message.company_code }}</p>
        <p>创建人：{{ message.admin_name }}</p>
        <p>手机：{{ message.admin_mobile }}</p>
        <p>
          <span> 入驻日期：{{ message.station_date }} </span>
        </p>
      </div>
      <div class="btnsError">
        <el-button
          style="width: 100%; font-size: 1.4em; line-height: 1.5"
          type="danger"
          @click="registerAgain"
          >重新注册</el-button
        >
      </div>
    </div>
    <!-- PC端 -->
    <div v-else>
      <el-col class="examinError" :span="24">
        <p><i class="el-icon-error"></i>审核完成，入驻失败</p>
      </el-col>
      <el-col class="messages" :span="24">
        <p>提交时间：{{ message.created_at }}</p>
        <p>
          {{ message.audit_opinion }}
        </p>
        <p>申请编号：{{ message.serial_number }}</p>
      </el-col>
      <el-col class="message" :span="24">
        <p>组织名称：{{ message.name }}</p>
        <p>统一社会信用代码：{{ message.company_code }}</p>
        <p>创建人：{{ message.admin_name }}</p>
        <p>手机：{{ message.admin_mobile }}</p>
        <p>
          <span> 入驻日期：{{ message.station_date }} </span>
        </p>
      </el-col>
      <el-col class="btnsError" :span="24">
        <el-button
          style="width: 100%; font-size: 18px"
          type="danger"
          @click="registerAgain"
          >重新注册</el-button
        >
      </el-col>
    </div>
  </el-row>
</template>

<script>
import { isMobile } from "@/utils/wxConfig.js";
export default {
  name: "examineStatus",
  props: ["message"],
  data() {
    return {
      status: false,
      isApp: false,
    };
  },
  mounted() {
    this.isApp = isMobile();
    // console.log('this.message',this.message);
    if (this.message.status == 2) {
      this.status = true; // 代表成功
    } else {
      this.status = false; // 代表失败
    }
  },
  methods: {
    registerAgain() {
      this.$emit("registerAgain");
    },
    loginBtn() {
      if (process.env.NODE_ENV === "production") {
        sessionStorage.removeItem("AccessToken");
      } else {
        localStorage.removeItem("AccessToken");
      }
      this.$emit("loginClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.examin {
  font-size: 28px;
  color: #7ac756;
  margin-bottom: 29px;
}
.examinError {
  margin-top: 30px;
  font-size: 28px;
  color: #f74848;
}
.message {
  height: 150px;
  background-color: rgba(252, 252, 252, 100);
  padding: 16px 24px;
  box-sizing: border-box;
  .company-name {
    color: #4387f8;
  }
  p {
    line-height: 24px;
    // font-weight: 800;
  }
  & > p:nth-child(4) {
    display: flex;
    justify-content: space-between;
    & > span:nth-child(2) {
      font-size: 14px;
    }
  }
}
.btns {
  position: relative;
  margin-top: 50px;
}
.btnsError {
  position: relative;
  margin-top: 40px;
}
.messages {
  & > p:nth-child(1) {
    margin-top: 20px;
    color: #999;
    font-size: 12px;
    line-height: 30px;
  }
  & > p:nth-child(2) {
    color: #f74848;
    font-size: 12px;
    line-height: 16px;
  }
  & > p:nth-child(3) {
    color: #333;
    font-size: 12px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

//app手机端
.app-status {
  font-size: calc(100vw / 750 * 20);
  padding-bottom: 2em;
  .examin {
    font-size: 1.6em;
    text-align: center;
    margin-top: 2em;
  }
  .examinError {
    font-size: 1.6em;
    text-align: center;
  }
  .messages {
    padding: 0 1.5em;
    margin-top: 2.6em;
    p {
      font-size: 1.4em;
      & + p {
        margin-top: 0.6em;
      }
    }
  }
  .message {
    height: auto;
    margin: 0 1.5em;
    margin-top: 2.5em;
    padding: 1.6em;
    background-color: #fafafa;
    p {
      font-size: 1.2em;
      margin-top: 0.6em;
      line-height: 1em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .btnsError {
    padding: 0 1.5em;
  }
  .btns {
    position: fixed;
    bottom: 1em;
    width: 34.5em;
    left: 1.5em;
  }
}
</style>
